<template>
  <custom-selector
    class="hub-selector"
    v-bind="$attrs"
    :items="hubIds"
    :placeholder="$t('navbar.hubSelector.placeholder')"
    :getItemLabel="getHubShortCode"
    v-on="$listeners"/>
</template>

<script>
import { mapGetters } from 'vuex'

const CustomSelector = () => import('@components/CustomSelector')

export default {
  name: 'hub-selector',
  components: {
    CustomSelector
  },
  props: CustomSelector.props,
  computed: {
    ...mapGetters('hub', [
      'getHubShortCode',
      'hubIds'
    ])
  }
}
</script>

<style lang="scss" scoped>
.hub-selector {
  max-width: 125px;

  &::v-deep {
    .dropdown-item:not(:last-child) {
      border-bottom: 1px solid $grey-lighter;
    }

    input {
      text-overflow: ellipsis;
    }
  }
}
</style>
